<template>
  <v-container fluid>
    <v-row row wrap>
        <template v-if="userInfo != undefined">
        <v-col cols="12" class="ma-2">
          <v-list two-line subheader>
         <v-list-item>
            <v-list-item-content>
                <v-list-item-title class="text-sm-left">{{ userInfo.fullName }} </v-list-item-title>
                <v-list-item-subtitle v-show="!invitee" class="text-xs-left">{{ memberNum }}</v-list-item-subtitle>
                <v-list-item-subtitle v-show="userInfo.userType=='keystone'" class="text-overline font-weight-bold text-left">*keystone*</v-list-item-subtitle>
                <v-list-item-subtitle v-show="invitee" class="text-xs-left">Membership Invitee</v-list-item-subtitle>
            </v-list-item-content>
             
            <template v-if="isAdmin">
                {{memberView ? "Member View": "Admin View"}}
                <v-btn icon color="primary" @click="toggleView">
                    <v-icon>streetview</v-icon>
                </v-btn>
            </template>
        </v-list-item>
            <template v-if="memberView">
                <v-card flat v-show="!invitee">
                    <v-list-item>
                        <v-list-item-content>
                        <v-list-item-subtitle class="text-xs-left">Email: {{ email }}</v-list-item-subtitle>
                        <v-list-item-subtitle class="text-xs-left">Proxy: {{ proxy }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-btn  outlined color="primary" @click="dialog = true">
                            Edit Profile
                        </v-btn>
                    </v-list-item>
                </v-card>
            </template>
          </v-list>
        </v-col>
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog = false">
                <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Member Profile</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="save">Save</v-btn>
            </v-toolbar-items>
            </v-toolbar>
            
            <v-col cols="12" ma-3>
                <v-subheader><strong>MEMBERSHIP STATUS</strong></v-subheader>
                <v-col pl-2 mx-3>
                    <v-card flat>
                        <div class="font-weight-thin">
                            Active Date: {{activeDate }} <br>
                            Expiration Date: {{expireDate }}
                        </div>
                 <!--   <v-card-actions><v-btn v-show="showMembershipRenewButton" outlined to="/join">Renew</v-btn></v-card-actions> -->
                    </v-card>
                </v-col>
                <v-divider></v-divider>
                <v-spacer></v-spacer>

                <v-subheader><strong>PROXY</strong></v-subheader>
                <v-col mx-3>
                    <v-text-field persistent-hint outlined
                        label="First and last name matching photo ID"
                        id="proxy"
                        type="name"
                        v-model="tempProxy"
                        hint="By entering a Proxy name, you authorize the Proxy to pickup pre-orders on your behalf. In order to pickup 
                            an order, the Proxy must show a copy of your order receipt and a photo ID matching this Proxy Name."
                        :rules="nameRules" 
                    ></v-text-field>
                </v-col>
                <v-divider></v-divider>
                <v-spacer></v-spacer>
                
                <template v-if="savedCards && savedCards.length>0">
                    <v-subheader><strong>PAYMENT CARDS ON FILE</strong></v-subheader>
                    <v-col mx-3>
                    <v-list subheader>
                        <v-list-item 
                            v-for="(card, index) in savedCards"
                            :key="index"
                            :value="index"
                            avatar
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{card.card_brand + ' *' + card.last_4}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                            <v-btn icon color="primary" @click="confirmDeleteCard = true">
                                <v-icon>delete</v-icon>
                            </v-btn>
                            <v-dialog v-model="confirmDeleteCard" persistent max-width="290">
                            <v-card>
                                <v-card-title class="headline">Confirm Delete Card</v-card-title>
                                <v-card-text>{{card.card_brand + ' *' + card.last_4}}</v-card-text>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text :loading="loading" @click="deleteCard(card)">Confirm</v-btn>
                                <v-btn color="light" text :loading="loading" @click="confirmDeleteCard = false">Cancel</v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>


                    </v-col>
                    <v-divider></v-divider>
                    <v-spacer></v-spacer>
                </template>

                <template v-if="allowPickupExtensionOptIn">
                    <v-subheader><strong>PICKUP EXTENSIONS</strong></v-subheader>
                    <v-list three-line subheader>
                    <v-list-item avatar>
                        <v-list-item-action>
                            <v-checkbox v-model="cancel" @click.stop="extend = false; cancel = true"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Cancel & Refund Orders</v-list-item-title>
                            <v-list-item-subtitle>Automatically cancel and refund any order that I have not picked 
                                up at the end of the Pickup Period</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item avatar>
                        <v-list-item-action>
                            <v-checkbox v-model="extend" @click.stop="extensionTerms = true"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Enroll in Extended Pickups</v-list-item-title>
                            <v-list-item-subtitle>You must agree to the terms of the 
                                <a href="javascript:;" @click.stop="extensionTerms = true">Pickup Extension Agreement</a>
                                </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list>
                </template>
              
                <v-divider></v-divider>
                <v-spacer></v-spacer>

                <v-subheader><strong>EMAIL</strong></v-subheader>
                <template v-if="allowEmailOptOut">
                    <v-list two-line subheader>
                    <v-list-item avatar>
                        <v-list-item-action>
                            <v-checkbox v-model="tempCorrespondence" ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Correspondence</v-list-item-title>
                            <v-list-item-subtitle>Send me member correspondence (new beer releases and other society-only correspondence)</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item avatar>
                        <v-list-item-action>
                            <v-checkbox v-model="tempConfirmations" ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Confirmations</v-list-item-title>
                            <v-list-item-subtitle>Send me transaction confirmations for orders, pickups, and refunds</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list>
                </template>
                <v-col ml-3 mr-3>
                    <v-checkbox v-model="editEmail"  label="I want to change my username/email:"></v-checkbox>
                    <v-text-field persistent-hint outlined :disabled="!editEmail"
                        label="Email"
                        id="email"
                        type="email"
                        v-model="tempEmail"
                        hint="This is your user name on this site and all member correspondence will be sent to this address."
                    ></v-text-field>
                </v-col>
                <v-divider></v-divider>
            </v-col>
        </v-card>
            <v-row  row justify-center>
                <v-dialog v-model="askToConfirm" persistent max-width="290">
                <v-card>
                    <v-card-title class="headline">Confirm Changes</v-card-title>
                    <ul>
                        <li v-for="change in changes">
                        Change {{ change.type }} to "{{ change.value }}"
                        </li>
                    </ul>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="writeChanges">Confirm</v-btn>
                    <v-btn color="light" text @click="askToConfirm = false">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
                <v-dialog v-model="extensionTerms" width="70%">
                    <v-card>
                        <v-card-title class="title">Extended Pickups Agreement</v-card-title>
                        <v-card-text>
                            By selecting this option, I agree to the following terms:
                            <br><br>
                            <ul>
                                <li>All of my current and future orders will be enrolled in Extended Pickups, which means they will be reserved and stored for me 
                                    to pickup for another 4 weeks <strong>in addition to</strong> the standard Pickup Period</li>
                                <li>I understand that if I do not pickup an extended order before the end of its 4 week extension period, 
                                    my order will be cancelled and I will <strong>NOT RECEIVE A REFUND</strong></li>
                                <li>An individual order will be extended if I am enrolled in Extended Pickups at the time when that order 
                                    reaches the end of its standard Pickup Period. </li>
                            </ul>
                        </v-card-text>
                        <v-card-actions>
                        <v-btn
                            text
                            color="secondary"
                            @click="extensionTerms = false; termsAccepted = false; cancel=true; extend=false"
                        >No Thanks</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="purple"
                            @click="extensionTerms = false; termsAccepted = true; cancel=false; extend=true"
                        >I Accept</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </v-dialog>
        </template>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/store/api'
export default {
    data () {
        return {
            dialog: false,
            tempCorrespondence: true,
            tempConfirmations: true,
            widgets: false,
            tempEmail: "",
            tempProxy: "",
            changes: [],
            askToConfirm: false,
            editEmail: false,
            allowEmailOptOut: true,
            allowPickupExtensionOptIn: false,
            extensionTerms: false,
            termsAccepted: false,
            cancel: true,
            extend: false,
            nameRules: [
                v => !!v || 'No Proxy',
                v => (v && v.length <= 25) || 'Must be less than 25 characters',
                v => v.trim().split(/\s+/).length > 1 && v.trim().split(/\s+/).length <= 4 || 'Must include first and last name (middle optional)'
            ],
            confirmDeleteCard: false,
            loading: false,
        }
    },
    mounted: function () {
       this.tempEmail = this.userInfo.email
       this.tempProxy = this.userInfo.proxy == undefined ? "": this.userInfo.proxy
       this.tempCorrespondence = this.userInfo.correspondence == undefined ? true: this.userInfo.correspondence
       this.tempConfirmations = this.userInfo.confirmations == undefined ? true: this.userInfo.confirmations
    },
    computed: {
        ...mapGetters([
            'isReady',
            'isAuthenticated',
            'memberStatus',
            'userInfo'
        ]),
        activeDate () {
            let date = this.userInfo.joinDate
            if(date == undefined)
                date = "2019-01-01" //no record exists before this date
            return date
        },
        expireDate () {
            let date = this.userInfo.expireDate
            if(date == undefined)
                date = "2019-12-31"
            return date
        },
        savedCards () {
            return this.userInfo.cards
        },
        userName(){
            let str = this.userInfo.firstName + " " + this.userInfo.lastName
            return str;
        },
        memberNum(){
            if(!this.memberView)
                return "Administrator"
            else return "Member #: " + this.userInfo.memberId
        },
        email(){
            return this.userInfo.email
        },
        proxy(){
            return this.userInfo.proxy
        },
        credit(){
            if(false)
                return this.userInfo.creditOnFile
            else 
                return "None"
        },
        countWords(str) {
            return str.trim().split(/\s+/).length
        },
        memberView () {
            return !this.userInfo.isAdmin || this.$store.state.adminMemberPreview
        },
        isAdmin () {
            return this.userInfo.isAdmin
        },
        showMembershipRenewButton () {
        return this.$store.state.membershipProduct != null
        },
        invitee () {return this.userInfo.userStatus == "invitee"},
    },
    methods: {
        save () {
            this.askToConfirm = false
            this.changes = []
            let currentEmail = this.userInfo.email
            let currentProxy = this.userInfo.proxy
            let currentCorrespondence = this.userInfo.correspondence == undefined ? true: this.userInfo.correspondence
            let currentConfirmations = this.userInfo.confirmations  == undefined ? true: this.userInfo.confirmations

            if(this.tempEmail !== currentEmail){
                this.changes.push({type: "email", value: this.tempEmail, previous: currentEmail})
            }
            if(this.tempProxy !== currentProxy){
                this.changes.push({type: "proxy", value: this.tempProxy, previous: currentProxy})
            }
            if(this.tempCorrespondence !== currentCorrespondence){
                this.changes.push({type: "correspondence", value: this.tempCorrespondence, previous: currentCorrespondence})
            }
            if(this.tempConfirmations !== currentConfirmations){
                this.changes.push({type: "confirmations", value: this.tempConfirmations, previous: currentConfirmations})
            }

            if(this.changes.length > 0)
                this.askToConfirm = true
            else
                this.dialog = false
        },
        writeChanges () {
            // write changes to firebase & state
            let userInfo = this.userInfo
            this.changes.forEach( change => {
                let type = change.type.toLowerCase()
                userInfo[type] = change.value //changes store locally
            })
            api.updateUserInfoAndAuth(userInfo, this.changes) //updates database
            this.askToConfirm = this.dialog = false
        },
        toggleView () {
            this.$store.state.adminMemberPreview = !this.$store.state.adminMemberPreview
        },
        deleteCard(card){
            console.log("deleting card *" + card.last_4)
            api.deleteCard(card)
            .then( response => {
                this.loading = false
                this.confirmDeleteCard = false
            })
            api.loadUserInfo() //this will clear card from GUI
        }
    }
}
</script>
