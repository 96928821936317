<template>
  <v-container fluid>
    <v-row row wrap>



      <template v-if="isReady && userInfoLoaded">
        <v-col cols="12" class="text-center mt-5" mt-3>
          <img src='../assets/ss.png' width="200">
        </v-col>
        <UserInfo />




        <template v-if="memberView">

          <template v-if="loading">
          <v-col cols="12" class="text-center mt-5" mt-3>
            <v-progress-circular indeterminate size="32"></v-progress-circular>
          </v-col>
        </template>

            <v-col v-show="!isActiveMember && !invitee" cols="12" ml-5 mr-5 class="text-center">
                <div  class="font-weight-bold">
                  {{welcomeMessage}}
              </div>
            </v-col>

            <template v-if="showMembershipPurchaseButton">
              <v-col cols="12" class="text-center" mt-3>
                <v-icon x-large class="mr-2" :color="purchaseButtonColor">{{purchaseButtonIcon}}</v-icon>
                <v-btn :color="purchaseButtonColor" rounded large to="/join">{{purchaseButtonText}}</v-btn>
              </v-col>
            </template>

        </template>
        <template v-else>
          <v-col cols="12" class="text-center" mt-3>

          </v-col>
        </template>
        </template>

      <template v-else>
        <v-col cols="12" class="text-center mt-5" mt-5>
          <img src='../assets/ss.png' width="200" >
        </v-col>
        <v-col cols="12" mt-3>
          <blockquote class="blockquote text-center">
            Learn more about membership <a href="https://www.wrenhousebrewing.com/society">here</a>
          </blockquote>
        </v-col>
        <v-col cols="12" class="text-center" mt-5 mb-5>
          <v-btn color="primary" to="/signin">Sign In</v-btn>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import UserInfo from './UserInfo'
import api from '@/store/api'
import AllMixins from '@/mixins/AllMixins'
export default {
  components: {
    UserInfo
  },
  name: 'Home',
  data () {
    return {
    }
  },
  mixins: [AllMixins],
  computed: {
    ...mapState([
      'alreadyRenewed',
    ]),
    ...mapGetters([
      'isReady',
      'isAuthenticated',
      'userInfo',
      'isActiveMember',
      'loading',
      'membershipProduct',
    ]),
    userInfoLoaded () {
      return this.$store.state.userInfo != null
    },
    memberView () {
      if(this.$store.state.userInfo == null) return false
      else return (!this.$store.state.userInfo.isAdmin || this.$store.state.adminMemberPreview)
    },
    superAdmin () {
      return this.$store.getters['isSuperAdmin']
    },
    showMembershipPurchaseButton () {
        return this.$store.state.membershipProduct != null// && this.$store.state.userInfo.isAdmin
    },
    invitee () {
      return this.$store.state.userInfo.userStatus == "invitee"
    },
    purchaseButtonText(){
      if(this.invitee){
        return "Purchase Membership For " + this.getMembershipProductYear()
      }
      else{
        if(this.alreadyRenewed){
          return "Your Membership is Renewed For " + this.getMembershipProductYear()
        }
        else{
          return "Renew Membership For " + this.getMembershipProductYear()
        }
      }
    },
    purchaseButtonColor(){
      return (this.alreadyRenewed ? "success": "warning")
    },
    purchaseButtonIcon(){
      return (this.alreadyRenewed ? "mdi-check-circle": "mdi-alarm")
    },
    welcomeMessage(){
      if(this.invitee){
        return "Welcome, you have been invited to join the Saguaro Society. Click the button below to purchase a membership."
      }
      else{
        if(!this.isActiveMember){
          if(this.$store.state.userInfo.expireDate < new Date().toISOString().substring(0,10))
            return "Your membership has expired."
          if(this.$store.state.userInfo.joinDate > new Date().toISOString().substring(0,10))
            return "Your membership will become active on " + this.formatDate(this.$store.state.userInfo.joinDate, true)
        }
      }
      return ""
    },
    // alreadyRenewed(){
    //   if(this.membershipProduct != null){
    //     let orders = this.$store.state.userOrders.filter(order => order.status != "Refunded" && order.productId == this.membershipProduct.productId)
    //     if(orders.length > 0){
    //       return true
    //     }
    //   }
    //   return false
    // },
  },
  // methods: {
  //   getUserMembershipStatus(){
  //     let orders = this.$store.state.userOrders.filter(order => order.status != "Refunded" && order.productId == this.membershipProduct.productId)
  //     this.alreadyRenewed = orders.length > 0
  //   },
  // },
}
</script>
