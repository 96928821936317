//import moment from 'moment-timezone'
import dayjs from 'dayjs'
import moment from 'moment-timezone'
import { mapGetters, mapMutations } from 'vuex'
export default {
    data () {
        return {
            audience: "",
            members: [],
        }
    },
    computed: {
        ...mapGetters([
       //     'members'
            'loading'
        ]),
        thisYear(){ //current year  
            return new Date().toISOString().substring(0,4)
        },
        nextYear(){ //next year
            return parseInt(this.thisYear) + 1
        },
        audiences(){
            let options = [
                {text: this.thisYear + ' Members - All', value: 'members'}, //active members only
            ]
        //    if(this.keystoneMembers.length > 0){ //differentiate between keystone and regular members
                options.push({text: this.thisYear + ' Members - Saguaro Only', value: 'saguaro'})
                options.push({text: this.thisYear + ' Members - Keystone Only', value: 'keystone'})
        //    }
            if(this.pendingInvitees.length > 0){
                options.push(
                    {text: this.nextYear + ' Invitees - New Saguaro Only', value: 'invitees'}, //saguaro invitatees only
                )
            }
            if(this.keystoneInvitees.length > 0){ //keystone members only
                options.push(
                    {text: this.nextYear + ' Invitees - Keystone Upgrades Only', value: 'ksInvitees'}, //keystone only
                )
            }    
                
            options.push(
                {text: this.thisYear + " + " + this.nextYear + ' Members - All', value: 'bridge'}, //includes active and future members
            )
            options.push(
                {text: this.nextYear + ' Members - New Additions Only', value: 'future'}, //includes active and future members
            )
            options.push(
                {text: 'Admins Only', value: 'admins'}
            )
            
            return options
        },
        fulfillmentOptions() {
            return [
                {type: 'pickup', location: 'Phoenix Taproom'},
                {type: 'pickup', location: 'Südhalle Taphouse'},
            ]
        },
        pendingInvitees () {
            let filteredMembers = this.members.filter(member => {
                return member.userStatus == 'invitee'
            })
            return filteredMembers
        },
        keystoneMembers () {
            let filteredMembers = this.members.filter(member => member.userType == "keystone")
            //console.log(this.members)
            return filteredMembers
        },
        keystoneInvitees () {
            let filteredMembers = this.members.filter(member => member.ksInvite == true)
            //console.log(filteredMembers)
            return filteredMembers
        },
    },

    methods: {
        ...mapMutations([
            'setLoading'
        ]),
        // this method in the store is for the signed in user; this one is to test any user
        userIsActiveMember(user){
            if(user == null || user == undefined || user.userStatus == "invitee")
                return false
            else{
                let now = moment().format("YYYY-MM-DD HH:mm")
                let start = user.joinDate
                let end = user.expireDate
                if(start == undefined || start == null) //these were not created for beginning of app 2019. only update if member renewed
                start = this.currentMembershipYear + "-01-01 00:00"
                if(end == undefined || end == null)
                end = this.currentMembershipYear + "-12-31 23:59"
            if(now >= start && now <= end)
                return true
            else
                return false
            }
        },
        isFutureMember(user){
            if(user == null || user == undefined || user.userStatus == "invitee" || user.joinDate == undefined || user.expireDate == undefined)
                return false
            else{
                return !this.isExpiredMember(user)
            }
        },
        isExpiredMember(user){
            if(user == null || user == undefined || user.userStatus == "invitee")
                return false
            else{   
                let now = moment().format("YYYY-MM-DD HH:mm")
                let end = user.expireDate
                if(end == undefined || end == null || now > end)
                    return true
            }
            return false
        },
        getAvailableProducts () {
            if( global.ENV.showExpiredProducts() ) {
              return this.getExpiredProducts()
            }
            else{
              this.$store.commit('setLoading', true)
              let items = []
              let now = moment().format("YYYY-MM-DD HH:mm")
              return global.FIREBASE.db.collection("products").where("orderEnd", ">=", now)
                .get()
                .then( querySnapshot => {
                  querySnapshot.forEach( doc => {
                    let item = doc.data()
                    //membership product is listed with beer products. skip membership products here
                    if(item.category == undefined || item.category != "Membership"){ 
                        //show all draft or approved products to admins
                        if(this.$store.getters['isAdmin'] == true)
                            items.push(doc.data())
                        else{
                            //is this an approved/active product?
                            if(item.status == undefined || item.status == null || item.status == "Approved"){
                                //is the member in the audience for this product?
                                if(this.audienceIncludesMember(item.audience, this.$store.state.userInfo)){
                                  items.push(doc.data())
                                }
                            }
                        }
                    }
                  })
                  this.$store.commit('setProducts', items)
                  this.$store.commit('setLoading', false)
                })
                .catch(error => {
              //    console.error("Error retreiving products: ", error);
                    this.$store.commit('setLoading', false)
                    //this.$store.commit('setError', error)
                })
            }
        },
        getExpiredProducts () {
            this.$store.commit('setLoading', true)
            let items = []
            let now = moment().format("YYYY-MM-DD HH:mm")
            return global.FIREBASE.db.collection("products").where("orderEnd", "<", now)
            .get()
            .then( querySnapshot => {
                querySnapshot.forEach( doc => {
                let item = doc.data()
                if(item.status == undefined || item.status == null || item.status == "Approved"){
                    items.push(doc.data())
                }
                else{
                    //item.status is Draft, only visible to admins
                    if(this.$store.getters['isAdmin'] == true)
                    items.push(doc.data())
                }
                })
                this.$store.commit('setProducts', items)
                this.$store.commit('setLoading', false)
            })
            .catch(error => {
                console.error("Error retreiving products: ", error);
                this.$store.commit('setLoading', false)
            })
        },
        audienceIncludesMember(audience, member){
            //default audience, if none specified, is all members
            if(!audience || audience == 'members'){
                if(this.includeMember(member, this.thisYear, "All", "Member", "All", "All"))
                    return true
            }
            if(audience == 'saguaro'){
                if(this.includeMember(member, this.thisYear, "Saguaro", "Member", "All", "All"))
                    return true
            }
            if(audience == 'keystone'){
                if(this.includeMember(member, this.thisYear, "Keystone", "Member", "All", "All"))
                    return true
            }
            if(audience == 'invitees'){
                if(this.includeMember(member, this.nextYear, "All", "Invitee", "All", "All"))
                    return true
            }
            if(audience == 'ksInvitees'){
                if(this.userIsActiveMember(member) && member.ksInvite == true)
                    return true
            }
            if(audience == 'bridge'){ //this year plus next year
                if(this.includeMember(member, this.thisYear, "All", "Member", "All", "All") || 
                    this.includeMember(member, this.nextYear, "All", "Member", "All", "All"))
                    return true
            }
            if(audience == 'future'){ // next year only
                if(!this.includeMember(member, this.thisYear, "All", "Member", "All", "All") && 
                    this.includeMember(member, this.nextYear, "All", "Member", "All", "All"))
                    return true
            }
            if(audience == 'admins'){
                if(member.isAdmin)
                    return true
            }
            return false
        },
        /**
         *  This method is duplicated on the server for sending emails
         * @param {*} audience 
         * @returns The members for the audience selected
         */
        getMembersForAudience(audience){
            let filteredMembers = []
            //console.log(audience)
            for(var i = 0; i < this.members.length; i++){
                let member = this.members[i]
                if(this.audienceIncludesMember(audience, member))
                    filteredMembers.push(member)
            }
            return filteredMembers
        },
        /**
         *  
         * @param {*} member
         * @param {*} year
         * @param {*} tier
         * @param {*} status
         * @param {*} history
         * @param {*} extension
         * @returns true if the member should be included in the audience
         *  This method is duplicated on the server for sending emails
         */ 
        includeMember(member, year, tier, status, history, extension, nextYearInvitation='All'){
            
            //exclude admins (they can be found with the audience filter)
            if(member.isAdmin)
                return false

            //tier
            if(tier == "All" || tier.toLowerCase() == member.userType){
                //status (userStatus started in 2020?)
                if((member.userStatus == undefined && status == "Member") || status.toLowerCase() == member.userStatus){
                    //extension
                    if(extension == "All" || (extension == "Yes" && member.extended) || (extension == "No" && !member.extended)){
                        
                        //next year invitation
                        if(nextYearInvitation == "All" || 
                            (nextYearInvitation == "Invite to Keystone" && member.ksInvite) || (nextYearInvitation == "Invite to Saguaro" && !member.ksInvite)){

                            //calculate time relevant for year and history filters
                            
                            let start = member.joinDate || "2019-01-01"
                            let end = member.expireDate || "2019-12-31"
                            let midYear = year + "-06-15" //midyear
                            let isMember =  (midYear > start && midYear < end) 
                            let renewedLast = start < (year + "-01-01")
                            let renewedNextNot = end <= (year + "-12-31")

                            //year
                            if(year == "All" || isMember || member.userStatus == "invitee"){
                                //history
                                if(history == "All" || (history == "Renewed From Last Year" && renewedLast) ||
                                        (history == "Not Renewed Next Year" && renewedNextNot) || 
                                        (history == "New Additions Only" && !renewedLast)){
                               //     if(member.joinDate == undefined || member.joinDate == null)
                               //         console.log(member.fullName)
                                    return true
                                }
                            }
                        }
                    }
                }
            }
            return false
        },

        isPendingInvitee(member){
            if(member.userType != "saguaro" || member.memberId == 0)
                return false
            if(member.userStatus == "invitee"){
                return true
            }
            return false
        },

        toDate(date){
            let dateObj = null
            if (typeof date === 'string' || date instanceof String)
                dateObj = new Date(date)
            else
                dateObj = date
            return dateObj
        },

        formatDate(dateStr, includeYear){
            // let date = new Date(dateStr)
            // //date.setTime(date.getTime() + (7*60*60*1000))
            // const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
            // let result = days[date.getDay()] + " " + (date.getMonth()+1) + "/" + date.getDate()
            // if(includeYear)
            //     result += ("/"+String(date.getFullYear()).substring(2,4))
            // return result

            let date = dayjs(dateStr)
            //date.setTime(date.getTime() + (7*60*60*1000))
            const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
            let result = days[date.day()] + " " + (date.month()+1) + "/" + date.date()
            if(includeYear)
                result += ("/"+String(date.year()).substring(2,4))
            return result
        },

        getMembershipProductYear(product) {
            if(product == null)
                product = this.$store.state.membershipProduct
            console.log(product)
            if(product == null)
                return null
            // Split the string into an array of words
            const words = product.name.split(' ');
            // Iterate through the words in reverse order
            for (let i = words.length - 1; i >= 0; i--) {
              // Check if the word is a number
              if (!isNaN(words[i])) {
                return parseInt(words[i]);
              }
            }
            // If no year is found, return null
            return null;
        },


        getDayCategories(daysBack, daysForward, minDate, maxDate){
            let date = new Date()
            date.setDate(date.getDate() - daysBack)
            let categories = []
            for(var i = -daysBack; i <= daysForward; i++){
                let dateStr = date.toISOString().substring(0,10)
                if((minDate == null || dateStr >= minDate) && (maxDate == null || dateStr <= maxDate))
                    categories.push(dateStr)
                date.setDate(date.getDate() + 1)
            }
            return categories
        },

        getWeekFormatted(date){
            let dateObj = this.toDate(date)
            var year = (''+dateObj.getFullYear()).substring(2,4)
            var oneJan = new Date(dateObj.getFullYear(),0,1)
            var numberOfDays = Math.floor((dateObj - oneJan) / (24 * 60 * 60 * 1000))
            var week = Math.ceil(( dateObj.getDay() + 1 + numberOfDays) / 7)
            week = (Array(2).join("0") + week).slice(-2) 
            return year + "-W" + week
        },

        getWeekCategories(weeksBack, weeksForward){
            let date = new Date()
            date.setDate(date.getDate() - 7*weeksBack)
            let categories = []
            for(var i = -weeksBack; i <= weeksForward; i++){
                categories.push(this.getWeekFormatted(date))
                date.setDate(date.getDate() + 7)
            }
            return categories
        },

        getMonthFormatted(date){
            let dateObj = this.toDate(date)
            return dateObj.toISOString().substring(0,7)
        },

        getMonthCategories(monthsBack, monthsForward){
            let date = new Date()
            date.setDate(15) //in case today is 31 in a 30 day month etc
            date.setMonth(date.getMonth() - monthsBack)
            let categories = []
            for(var i = -monthsBack; i <= monthsForward; i++){
                categories.push(this.getMonthFormatted(date))
                date.setMonth(date.getMonth() + 1)
            }
            return categories
        },

        getQuarterFormatted(date){
            let dateObj = this.toDate(date)
            let month = dateObj.toISOString().substring(5,7)
            let quarter = Math.floor((parseInt(month)-1)/3) + 1
            return dateObj.toISOString().substring(0,5) + "Q" + quarter
        },

        getLabelForDate(type, date){

            if(type == undefined || date == undefined || date == '')
                return null

            //remove e.g. "last year" from "last year by month"
            let index = type.search(/by/i)
            if(index > 0)
                type = type.substring(index)    

            let label = date
            if(type.toLowerCase().includes('week'))
                label = this.getWeekFormatted(date)
            if(type.toLowerCase().includes('month'))
                label = this.getMonthFormatted(date) 
            if(type.toLowerCase().includes('quarter'))
                label = this.getQuarterFormatted(date)  
            
                if(type.toLowerCase().includes('year'))
                label = date.substring(0,4)   
            if(type.toLowerCase().includes('this year'))
                label = this.getMonthFormatted(date)  
            if(type.toLowerCase().includes('last year'))
                label = this.getMonthFormatted(date)  


            //if not by week or by month, then return it is by day, return the input day
            return label
        },

        getLabelForDay(type, day){
            let label = day
            if(type.includes('by Week'))
                label = this.getWeekFormatted(day)
            if(type.includes('by Month'))
                label = this.getMonthFormatted(day)  
            //if not by week or by month, then return it is by day, return the input day
            return label
        },

        getTimePeriod(reportTimeType){

            //only one time frame for day (last month)
            if(reportTimeType.includes('by Day'))
                return "Past Month"

            let type = reportTimeType.includes('by Week') ? "Week" : "Month"
            let period = reportTimeType.replace("by Month", "")
            period = period.replace("by Week", "").trim()

            if(period == "Current"){
                if(type == "Month"){
                    return "Past 18 Months"
                }
                else{
                    return "Past 6 Months"
                }
            }
            else{ 
                if(period == "This Year"){
                    return "This Year"
                }
                if(period == "Last Year"){
                    return "Last Year"
                }
            }
            return null
        },


        /*
        *
        * report time types = ['Current by Week','Current by Month', 'This Year by Month', 'Last Year by Month'],
        */
        getTimeCategories(reportTimeType){
            let today = new Date()

            if(reportTimeType == 'Last 30 Days')
                return this.getDayCategories(29,0)

            if(reportTimeType == 'Last 26 Weeks')
                return this.getWeekCategories(25,0)

            if(reportTimeType == 'Last 12 Months')
                return this.getMonthCategories(11,0)

            if(reportTimeType == 'Last 24 Months')
                return this.getMonthCategories(23,0)

            let thisMonth = today.getMonth()
            if(reportTimeType == "This Year"){
                return this.getMonthCategories(thisMonth, 11-thisMonth)
            }
            if(reportTimeType == "Last Year"){
                return this.getMonthCategories(thisMonth+12, -(thisMonth+1))
            }
            return null
        },

        formatCurrency(num, includeCents){
            const dollars = new Intl.NumberFormat(`en-US`, {
                    currency: `USD`,
                    style: 'currency',
                    maximumFractionDigits: (includeCents?2:0)
                }).format(num)
            return dollars
        },


        //TODO put these values into database
        getRetailHours(day, type){
            let location = "Phoenix"
            if(type != "open" && type != "close")
                type = "open"
            let hours = {
                "Phoenix": {
                    "Monday": {open: "12:00", close: "22:00"},
                    "Tuesday": {open: "12:00", close: "22:00"},
                    "Wednesday": {open: "12:00", close: "22:00"},
                    "Thursday": {open: "12:00", close: "22:00"},
                    "Friday": {open: "11:00", close: "23:59"},
                    "Saturday": {open: "11:00", close: "23:59"},
                    "Sunday": {open: "11:00", close: "21:00"},
                }
            }
            return hours[location][day][type]
        },

        getDefaultOrderWindows(releaseDate, releaseType){
            let pickup = {start:{}, end:{}}
            let order = {start:{}, end:{}}

            let pickupDays = 7
            if(releaseType == "Member Only")
                pickupDays = 31

            pickup.start.date = releaseDate
            pickup.start.time = this.getRetailHours(dayjs(releaseDate).format('dddd'), "open")
            const pickupDate = dayjs(releaseDate).add(pickupDays, 'days')
            pickup.end.date = pickupDate.format().substring(0,10)
            pickup.end.time = this.getRetailHours(pickupDate.format('dddd'), "close")
            
            let start = null
            //use current time as start of order window
           // console.log(releaseDate)
            //if(dayjs(releaseDate).isAfter(dayjs())){ 
                //use current time if release is in future
                start = dayjs().format().split("T")
            //}
            //else{
            //    //use release date minus 2 days if release is in past (catch up inputs)
            //    start = dayjs(releaseDate).subtract(2,'days').format().split("T")
           // }
            order.start.date = start[0]
            order.start.time = start[1].substring(0,5)
            
            //use 2 hours before release time (pickup start)
            

            if(releaseType == "Member Only"){
                //use 30 days after release date at end of day
                const orderEndDate = dayjs(releaseDate).add(30, 'days')
                order.end.date = orderEndDate.format().substring(0,10)
                order.end.time = this.getRetailHours(orderEndDate.format('dddd'), "close")
            }
            else{
                let end = dayjs(pickup.start.date + "T" + pickup.start.time)
                end = end.subtract(2,'hours')
                end = end.format().split("T")
                order.end.date = end[0]
                order.end.time = end[1].substring(0,5)
            }
        
            return {pickup: pickup, order: order}
        },

        delayForWeekend(date){
            if(date.getDay() == 5) //saturday
                date.setDate(date.getDate() + 2)
            if(date.getDay() == 6) // sunday
                date.setDate(date.getDate() + 1)
            return date
        },

        convertUTCDateToLocalDate(date){
            date = this.toDate(date)
            var dateLocal = new Date(date);
            var newDate = new Date(dateLocal.getTime() - dateLocal.getTimezoneOffset()*60*1000);
            return newDate;
        },

        async getAllUsers () {
            this.setLoading(true)
            this.members = []
            let usersRef = FIREBASE.db.collection('users')//.where('userStatus', '==', 'member')
            let userDocs = await usersRef.get();
            for(const doc of userDocs.docs){
                this.members.push(doc.data())
            }
            this.setLoading(false)
        },

        titleCase(str){
            return str.toLowerCase().split(' ').map(function(word) {
                return word.replace(word[0], word[0].toUpperCase());
            }).join(' ');
        },


    }
}